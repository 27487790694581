import {closest} from './_closest';

export function hover() {
  const hov = document.querySelectorAll('a, button, .hov');
  if(IS_TOUCH) {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('touchstart', function(e) {
        this.classList.add('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.add('hover-parent');
        }
      });
      el.addEventListener('touchend', function(e) {
        this.classList.remove('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.remove('hover-parent');
        }
      });
    };
  } else {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('mouseenter', function(e) {
        this.classList.add('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.add('hover-parent');
        }
      });
      el.addEventListener('mouseleave', function(e) {
        this.classList.remove('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.remove('hover-parent');
        }
      });
    };
  }
}