// Stylesheets
import './assets/scss/site.scss';

import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {lazyload} from './assets/modules/lazyload';
const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  lazyload();
}

init();